import React, { FunctionComponent, useEffect, useState } from 'react';
import NoticesPage, { Row } from '../../view/component/page/NoticesPage'
import { getNoticesEditPagePath, getNoticesRegisterPagePath } from '../../lib/app-path';
import { CallabtSDK } from 'callabt-sdk';
import PageWrapper from '../../lib/PageWrapper';
import { navigate } from 'gatsby';

const Notices: FunctionComponent = () => {
  const [rows, setRows] = useState<Row[]>([]);

  const fetch = async () => {
    try {
      const { notices } = await CallabtSDK.getNotices();
      setRows(notices);
    } catch (error) {
      console.error(error);
      alert('공지사항을 불러오는 데 실패했습니다.');
    }
  };

  const onClickEdit = (id: number) => {
    navigate(getNoticesEditPagePath({ id }));
  };

  const onClickRegisterButton = () => {
    navigate(getNoticesRegisterPagePath());
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper>
      <NoticesPage
        rows={rows}
        onClickEdit={onClickEdit}
        onClickRegisterButton={onClickRegisterButton}
      />
    </PageWrapper>
  );
};

export default Notices;
